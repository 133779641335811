import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'presenceStatus',
    standalone: true
})
export class PresenceStatusPipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): string {
        switch (value) {
            case 'presenceunknown':
            case 'unknown':
                return 'Unknown';
            case 'berightback':
                return 'Be Right Back';
            case 'busy':
                return 'Busy';
            case 'donotdisturb':
                return 'Do Not Disturb';
            case 'away':
                return 'Away';
            case 'offline':
                return 'Offline';
            case 'available':
                return 'Available';
            default:
                return 'Unknown';
        }
    }
}
