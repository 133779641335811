<div
    *ngIf="mode === 'line'"
    class="presence"
    [tooltip]="presence | presenceStatus"
    [class.available]="presence === 'available'"
    [class.away]="presence === 'away' || presence === 'berightback'"
    [class.busy]="presence === 'busy' || presence === 'donotdisturb'"
    [class.offline]="presence === 'offline' || presence === 'unknown' || presence === 'presenceunknown'"></div>

<i
    *ngIf="mode === 'square' || mode === 'square-xl'"
    [tooltip]="presence | presenceStatus"
    class="square bi bi-square-fill px-1"
    [class.available-square]="presence === 'available'"
    [class.away-square]="presence === 'away' || presence === 'berightback'"
    [class.busy-square]="presence === 'busy' || presence === 'donotdisturb'"
    [class.offline-square]="presence === 'offline' || presence === 'unknown' || presence === 'presenceunknown'"
    [class.square-xl]="mode === 'square-xl'"></i>

<div
    *ngIf="mode === 'circle'"
    class="position-absolute translate-middle bottom-0 start-100 rounded-circle h-15px w-15px"
    [tooltip]="presence | presenceStatus"
    [class.available-circle]="presence === 'available'"
    [class.away-circle]="presence === 'away' || presence === 'berightback'"
    [class.busy-circle]="presence === 'busy' || presence === 'donotdisturb'"
    [class.offline-circle]="presence === 'offline' || presence === 'unknown' || presence === 'presenceunknown'"></div>
